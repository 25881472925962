import {useState} from 'react'
import Home from './Home';
import 'bootstrap/dist/css/bootstrap.min.css';


const App = ({list, setList}) => {

  

  return(
    <div>

      <Home ></Home>
    </div>
  );
}

export default App;
